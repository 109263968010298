// import "@bam-spark/nav-toggle";
require('waypoints/lib/noframework.waypoints.js');
require('headroom.js/dist/headroom.min.js');

import Headroom from "headroom.js/dist/headroom.min.js";
const header = document.querySelector(".c-header__wrapper");
var options = {
    offset : 39,
}

const headroom = new Headroom(header, options);
headroom.init();


let navToggle = document.querySelector('.js-nav-toggle');
let menuWrapper = document.querySelector('.c-nav--primary__wrapper');

navToggle.addEventListener('click', function () {

  if (navToggle.classList.contains('is-active')) {
    navToggle.classList.remove('is-active');
    menuWrapper.classList.remove('is-active');
    document.body.classList.remove("menu-open");
    this.setAttribute('aria-expanded', 'false');

  } else {
    navToggle.classList.add('is-active');
    menuWrapper.classList.add('is-active');
    document.body.classList.add("menu-open");
    this.setAttribute('aria-expanded', 'true');
  }
});

//mobile nav drop down
var mobileNav = document.querySelectorAll('.has-dropdown');

for (var i = 0; i < mobileNav.length; i++ ) {
  var mobileList = mobileNav[i];

  var dropdownToggle = mobileList.querySelector('.js-dropdown-toggle');

  dropdownToggle.addEventListener('click', function() {
    var dropMenu = this.nextElementSibling;

    if (this.classList.contains('plus-active')) {
      this.classList.remove('plus-active');
    } else {
      // Open Toggle
      this.classList.add('plus-active');
    }

    if (dropMenu.classList.contains('dropdown-active')) {
       // Close Toggle
        dropMenu.classList.remove('dropdown-active');
        dropMenu.setAttribute('aria-expanded', 'false');

      } else {
        // Open Toggle
        dropMenu.classList.add('dropdown-active');
        dropMenu.setAttribute('aria-expanded', 'true');
      }
  });
};

const toggles = document.querySelectorAll('.js-toggle');

for (let a of toggles) {
    a.addEventListener('click', function (event) {

      if (a.classList.contains('utl-is-active')) {
        a.classList.remove('utl-is-active');
        a.setAttribute('aria-expanded', 'false');
        event.preventDefault();

      } else {
        a.classList.add('utl-is-active');
        a.setAttribute('aria-expanded', 'true');
        event.preventDefault();
      }
    });
}

let mapClose = document.querySelector('.c-map-drawer__map__close-button');
let mapDrawer = document.querySelector('.c-map-drawer__controls');

mapClose.addEventListener('click', function () {
    mapDrawer.classList.remove('utl-is-active');
    mapDrawer.setAttribute('aria-expanded', 'false');
    event.preventDefault();
});

var elements = document.getElementsByClassName('js-animate');

for (var i = 0; i < elements.length; i++) {
    var element = elements[i];
    element.className += ' js-animate--pending';
}

var continuousElements = document.getElementsByClassName('js-animate')
for (var i = 0; i < continuousElements.length; i++) {
  new Waypoint({
    element: continuousElements[i],
    handler: function() {
      this.element.classList.add("js-animate--start")
  },
    offset: '97%'
  })
}

var lazyLoadInstance = new LazyLoad({
    elements_selector: ".js-lazy"
});
